/* Button */
.ant-btn-primary {
    background-color: #00B7CE;
    color: #fff;
    font-weight: 500;
}

.ant-btn-primary:not(:disabled):hover {
    background-color: #007895;
}

.ant-btn-link {
    color: #00B7CE;
}

.ant-btn-link:not(:disabled):hover {
    color: #007895;
}

.ant-btn-default:not(:disabled):hover {
    border-color: #039eb2;
    color: #039eb2;
}

/* End Button */

/* Sidebar */
.ant-layout-sider-children {
    background-color: #001529;
}

.ant-layout-sider .ant-layout-sider-dark .bg-dark {
    background-color: #001529;
}

.ant-layout-sider .ant-menu {
    background-color: #001529;
}

.ant-menu-dark .ant-menu-item-selected {
    background-color: #052644;
}

.ant-menu-item.ant-menu-item.ant-menu-item-only-child:focus {
    background-color: #052644;
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: #001e3a;
}

.ant-menu .ant-menu-submenu-arrow {
    color: #fff;
    /* font-weight: 500; */
}

/* End Sidebar */

.ant-switch.ant-switch-checked {
    background-color: #039eb2;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: #039eb2;
}

.ant-switch-inner {
    .ant-switch-inner-checked {
        background-color: #039eb2;
    }
}