* {
  margin: 0;
}

.table-collapse {
  border-collapse: collapse;
  border: 1px solid #ededed;
}

.table-collapse th,
.table-collapse td {
  border-collapse: collapse;
  border: 1px solid #ededed;
  border-radius: 5px;
}

.table-default th,
.table-default td {
  padding: 10px;
}

.notification:hover {
  background-color: rgb(219, 250, 255);
  border-radius: 5px;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.sidebar-scroll::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.sidebar-scroll::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

.sidebar-scroll::-webkit-scrollbar-thumb:active {
  background: #000000;
}

.sidebar-scroll::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.sidebar-scroll::-webkit-scrollbar-track:hover {
  background: #666666;
}

.sidebar-scroll::-webkit-scrollbar-track:active {
  background: #333333;
}

.sidebar-scroll::-webkit-scrollbar-corner {
  background: transparent;
}

.table-custom {
  border: 1px solid #efefef;
  border-collapse: collapse;
  padding: 5px;
  width: 100%;
}

.table-custom th {
  border: 1px solid #efefef;
  padding: 5px;
  background: #f0f0f0;
  color: #313030;
  text-align: center;
}

.table-custom td {
  cursor: pointer;
  border: 1px solid #efefef;
  text-align: center;
  padding: 5px;
  background: #ffffff;
  color: #313030;
  text-align: center;
}

.a-link {
  width: 100%;
  color: black;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
}

.a-link:hover {
  width: 100%;
  color: black;
  padding: 5px;
  background-color: #eaeaea;
  border-radius: 5px;
}

.blinking-badge {
  -webkit-animation: blink 2s infinite;
  -moz-animation: blink 2s infinite;
  -o-animation: blink 2s infinite;
  animation: blink 2s infinite;
}

@-webkit-keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}