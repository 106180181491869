.login-body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    color: #303433;
}

.login-body-container {
    min-height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section.login-side {
    background: url(../../../assets/images/bk.png) no-repeat;
    background-size: 100% 102%;
}

.login-image-logo {
    width: 50%;
}

.login-image-ilustration {
    width: 80%;
    max-width: 80%;
}

.login-container {
    max-width: 450px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-title {
    text-transform: uppercase;
    font-size: 3em;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
}

.login-separator {
    width: 150px;
    height: 4px;
    background-color: #00B7CE;
    margin: 24px;
}

.login-welcome-message {
    text-align: center;
    font-size: 1.1em;
    line-height: 28px;
    margin-bottom: 30px;
    color: #696969;
}

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.login-form-control {
    width: 100%;
    position: relative;
    margin-bottom: 24px;
}

.button-login {
    border: none;
    outline: none;
    border-radius: 30px;
    font-size: 1.1em;
    height: 50px;
}

input {
    width: 100%;
    background: #e6e6e6;
    color: #333;
    letter-spacing: 0.5px;
    padding: 14px 64px;
}

input~i {
    position: absolute;
    left: 32px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    transition: color 0.4s;
}

input:focus~i {
    color: #00B7CE;
}

.button-login {
    color: #fff;
    padding: 14px 64px;
    margin: 32px auto;
    box-sizing: border-box;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    background-image: linear-gradient(to right, #00B7CE, #0a8090);
    cursor: pointer;
    transition: opacity 0.4s;
}

.button-login:hover {
    opacity: 0.9;
}

/* ----  Responsiveness   ----  */
@media (max-width: 780px) {

    .login-body-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-side {
        display: none;
    }
}